import { ref } from 'vue'
import { axiosInstance } from '@/library'

export function useLoadAnimals(type, breed) {
  const animals = ref([])
  const error = ref(null)

  axiosInstance.get(`${window.VUE_APP_GRANJA}/api/animals/${type}/${breed}`)
    .then(response => {
      if (response.status === 200) {
        animals.value = response.data.animals.sort((a, b) => (a.ageInMonths > b.ageInMonths) ? 1 : ((b.ageInMonths > a.ageInMonths)) ? -1 : 0)

        // this.zones.sort((a, b) => (a.zoneName > b.zoneName) ? 1 : ((b.zoneName > a.zoneName) ? -1 : 0))
      }
    })
    .catch((err) => { error.value = err })

  return { animals, error }
}
