<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <div>
        <h1>Cattle</h1>
        <p>
          Our cattle is of mixed breed and the animals live in a complete herd. That means we keep the bulls, cows, and
          calves together. We don't have a barn. The animals live outside the whole year round. Due to the large number
          of trees there is plenty of natural shelter available.
        </p>
        <picture>
          <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-04-25_20-15-53.jpg">
          <img src="../../assets/images/2022-04-25_20-15-53-640.jpg">
        </picture>
        <p>
          We don't have any "milk cows" which means a cow that produces a lot more milk than needed to feed one or two
          calves. Our cattle is of the beef type.
        </p>

        <h2>Weaning</h2>
        <p>
          We do not wean our calves. Instead we let the cows and calves decide when it is time to stop drinking milk.
          There is also no separation of calves from the cows.
        </p>

        <div v-if="isEnabled(LIST_ANIMALS)">
          <div v-if="isEnabled(SPONSOR_ANIMALS)">
            <h2>For Meat or for Land Restoration</h2>
            <p>
              Some people prefer to buy meat for their family and themselves while others prefer to have animals around
              for land restoration. As we understand our operation primarily as an <router-link
                :to="{ name: 'concept' }">ecosystem restoration project
              </router-link> we leave you the choice.
            </p>
          </div>
          <div v-else>
            <h2>Beef for Your Family</h2>
            <p>
              As we understand our operation primarily as an <router-link :to="{ name: 'concept' }">ecosystem
                restoration project </router-link> we are not trying to fill the virtual shelves of our shop with lots
              of beef packages on sale. Instead we indicate which animals are available for sale and only once all parts
              of the animal have been sold the process of butchering will start.
            </p>
          </div>

          <div v-if="isEnabled(LIST_ANIMALS)">
            <h2>Cattle on the Farm</h2>
            <p>
              Currently we keep {{ animals.length }} head of cattle on the farm.
              <span v-if="hasPorkMeatSales">If you are interested in buying meat for your family, just use the buy
                button
                where it appears.</span>
            </p>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Age</th>
                  <th>Sex</th>
                  <th>Life Status</th>
                  <th v-if="hasSponsorAnimals">Status</th>
                  <th v-if="hasSponsorAnimals || hasPorkMeatSales">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="animal in animals" :key="animal.externalId">
                  <td>{{ animal.externalId }}</td>
                  <td nowrap>{{ $n(animal.ageInMonths / 12, 'decimal') }} years</td>
                  <td>{{ $t('animalListing.' + animal.sex) }}</td>
                  <td nowrap>{{ $t('terms.' + animal.lifeStatus.toLowerCase()) }} <span v-if="animal.readyForSale">( {{
                    $t('animalListing.forSale') }} )</span></td>
                  <td v-if="hasSponsorAnimals">
                    Pasture or other
                  </td>
                  <td width="100%" nowrap>
                    <button v-if="hasPorkMeatSales && animal.readyForSale" class="animal-buy"
                      @click.stop="buyForMeat(animal.animalId)">Buy Meat</button>
                    <button v-if="hasSponsorAnimals && animal.readyForSale" class="animal-buy"
                      @click.stop="buyForLife(animal.animalId)">Sponsorship</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/*eslint no-unused-vars: "off"*/
import { useLoadAnimals } from '@/animals'
import { isEnabled, LIST_ANIMALS, SPONSOR_ANIMALS } from '@/features'

const { animals, error } = useLoadAnimals('CATTLE', 'MIXED')
</script>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';

export default {
  name: "CattleEN",
  components: {
    Sidebar,
  },

  computed: {
    animalCount () {
      return this.animals.length
    }
  },

  methods: {
    buyForLife (animalId) {
      this.$router.push({ name: 'buyForLife', params: { animalId } })
    },

    buyForMeat (animalId) {
      this.$router.push({ name: 'buyForMeat', params: { animalId } })
    },

  }

};
</script>


<style scoped>
TABLE {
  border-collapse: separate;
  border-spacing: 20px 0;
}

TH {
  text-align: left;
  white-space: nowrap;
}
</style>
